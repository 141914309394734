import React from "react";

export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="copyright">
          <p>
            Copyright © All Rights Reserved by{" "}
            <a href="/" target="_blank">
              LVS
            </a>{" "}
            2024
          </p>
        </div>
      </div>
    </>
  );
}
