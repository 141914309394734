import React, { useContext, useEffect, useState } from "react";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Footer from "../Comman/Footer";
import axios from "axios";
import { GMCContext } from "../../GMCContext";
import Pagination from "../Comman/Pagination";
import Copy from "../Comman/Copy";
import { useParams } from "react-router-dom";

export default function NinetyThreeThousandFivehundred() {
  const { formatAddress, web3, contract, formatDateTime, isToggled, toggleMenu } =
    useContext(GMCContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const getData = async () => {
    try {
      setLoading(true);
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "salary",
          submethod: "getsalarybyid",
          key: process.env.REACT_APP_KEY,
          rank: "Sales Representative",
          search,
          team_business: 93500,
          page: currentPage,
          pageSize: pageSize,
        })
        .then((res) => {
          setLoading(false);
          // console.log("res.data :",res.data)
          if (res.data.error) {
            return "";
          }
          setdata(res.data.data);
          setPages(Math.ceil(res.data.dataLength / pageSize));
        });
    } catch (err) {
      console.log("error : ", err);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
    getData();
  }, [pages, pageSize, currentPage, search]);
  return (
    <>
      <div id="main-wrapper" className={isToggled ? "show menu-toggle" : "show"}>
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active text-primary">
                  Salary 93500
                </li>
              </ol>
            </div>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-center">
              <div className="input-group search-area d-inline-flex me-2">
                <input
                  type="text"
                  className="form-control pe-0"
                  placeholder="Search here"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="input-group-text "
                    onClick={() => setSearch("")}
                  >
                    {search ? (
                      <img
                        src="./assets/images/close.png"
                        alt=""
                        style={{ height: "30px" }}
                      />
                    ) : (
                      <i className="flaticon-381-search-2" />
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body ">
                    <div className="d-flex justify-content-between">
                      <div className="card-title text-light">Salary 93500</div>
                      <span
                        className="btn btn-primary btn-sm "
                        onClick={() => getData()}
                      >
                        <i
                          className="flaticon-381-repeat-1 "
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <hr />
                    <div className="table-responsive">
                      <table
                        id="example5"
                        className="table table-striped patient-list mb-4 dataTablesCard fs-14"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Address</th>
                            <th>Income</th>
                            <th>Week</th>
                            <th>Flushed</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data && data?.length
                            ? data.map((ele, i) => {
                              let ind =
                                currentPage * pageSize - pageSize + (i + 1);
                              return (
                                <tr>
                                  <td>{ind}</td>
                                  <td>
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={`${process.env.REACT_APP_EXPLORER}/address/${ele.address}`}
                                      rel="noreferrer"
                                    >
                                      {formatAddress(ele.address)}
                                    </a>
                                    {ele.address ? (
                                      <Copy data={ele.address} />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <span>
                                      ${ele.income ? ele.income : "0"}
                                    </span>
                                  </td>
                                  <td>{ele.week}</td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      {ele.flushed === true ? (
                                        <>
                                          <div className="green-dot-active mx-2"></div>
                                          Active
                                        </>
                                      ) : (
                                        <>
                                          <div className="red-dot-active mx-2"></div>
                                          Deactive
                                        </>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <span>
                                      {ele.datetime}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })
                            : ""}
                        </tbody>
                      </table>
                      {loading ? (
                        <div className="text-center">
                          <p>Loading...</p>
                        </div>
                      ) : !data || !data.length ? (
                        <div className="text-center">
                          <p>No data found.</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
