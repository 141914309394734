import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [error, seterror] = useState("");
  const navigate = useNavigate();

  const getCheck = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "adminLogin",
        submethod: "check",
        username: username,
        password: password,
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // console.log("res.data :", res.data);
        if (res.data.error) {
          return "";
        }
        if (res.data === "1") {
          localStorage.setItem("odkpfub", "5Dg4ZCDAxWoxjgGHRj6BAMQ4dUDC");
          // navigate("/authentication", {
          //   state: { username: res.data.username, _id: res.data._id },
          // });
          navigate("/dashboard", {
            // state: { username: res.data.username, _id: res.data._id },
          });
        } else {
          seterror("Invalid credential");
        }
      });
  };

  return (
    <>
      <div className="authincation vh-100">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mb-3">
                        <a href="/">
                          <img src="assets/images/logo192.png" alt="" />
                        </a>
                      </div>
                      <div className="form-group">
                        <label className="form-label">UserName</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setusername(e.target.value)}
                        />
                      </div>
                      <label className="form-label">Password</label>
                      <div className="mb-3 position-relative">
                        <input
                          type="password"
                          id="dz-password"
                          className="form-control"
                          onChange={(e) => setpassword(e.target.value)}
                          onKeyPress={(e) =>
                            e.charCode === 13 ? getCheck() : ""
                          }
                        />
                      </div>
                      {error ? (
                        <div class="alert alert-danger solid alert-square ">
                          {error}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                          onClick={() => getCheck()}
                        >
                          Log In
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
