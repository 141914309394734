import React, { useContext, useEffect, useState } from "react";
import { GMCContext } from "../../GMCContext";
import axios from "axios";
import Pagination from "../Comman/Pagination";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Copy from "../Comman/Copy";
import Footer from "../Comman/Footer";

function RDList({ address }) {
  const { formatAddress, web3, contract, formatDateTime, isToggled, toggleMenu } =
    useContext(GMCContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const getData = async () => {
    try {
      setLoading(true);
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "rdlist",
          submethod: "get",
          key: process.env.REACT_APP_KEY,
          search: search,
          page: currentPage,
          pageSize: pageSize,
        })
        .then((res) => {
          setLoading(false);
          if (res.data.error) {
            return "";
          }
          setdata(res.data.data);
          setPages(Math.ceil(res.data.dataLength / pageSize));
        });
    } catch (err) {
      console.log("error : ", err);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
    getData();
  }, [pages, pageSize, currentPage, search]);
  return (
    <>
      <div id="main-wrapper" className={isToggled ? "show menu-toggle" : "show"}>
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active text-primary">RD List</li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="form-head d-flex align-items-center">
                      <div className="input-group search-area d-inline-flex m-3">
                        <input
                          type="text"
                          className="form-control form-control-rev pe-0"
                          placeholder="Search here"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="input-group-text input-group-text-rev "
                            onClick={() => setSearch("")}
                          >
                            {search ? (
                              <img
                                src="./assets/images/close.png"
                                alt=""
                                style={{ height: "30px" }}
                              />
                            ) : (
                              <i className="flaticon-381-search-2" />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <h3 class="py-3 px-5">Bonanza Reward</h3>
                    <hr />
                    <div className="table-responsive">
                      <table
                        id="example5"
                        className="table table-striped patient-list mb-4 dataTablesCard fs-14"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Address</th>
                            <th>Ref.Address </th>
                            <th>Package</th>
                            <th>Amt</th>
                            <th>Token</th>
                            <th>Premiums</th>
                            <th>Rate</th>
                            <th>Date</th>
                            <th>M.Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data && data?.length
                            ? data.map((e, i) => {
                              let ind =
                                currentPage * pageSize - pageSize + (i + 1);
                              return (
                                <tr>
                                  <td>{ind}</td>
                                  <td className="text-primary">
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={`${process.env.REACT_APP_EXPLORER}/address/${e.address}`}
                                      rel="noreferrer"
                                    >
                                      <span>
                                        First Name:{" "}
                                        {e.first_name ? e.first_name : "-"}
                                      </span>
                                      <br />
                                      <span>
                                        Last Name:{" "}
                                        {e.last_name ? e.last_name : "-"}
                                      </span>{" "}
                                      <br />
                                      <span>
                                        Email: {e.email ? e.email : "-"}
                                      </span>{" "}
                                      <br />
                                      <span>
                                        Address: {formatAddress(e.address)}
                                      </span>
                                    </a>
                                    {e.address ? (
                                      <Copy data={e.address} />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={`${process.env.REACT_APP_EXPLORER}/address/${e.ref_address}`}
                                      rel="noreferrer"
                                    >
                                      <span>
                                        First Name:{" "}
                                        {e.ref_first_name
                                          ? e.ref_first_name
                                          : "-"}
                                      </span>
                                      <br />
                                      <span>
                                        Last Name:{" "}
                                        {e.ref_last_name
                                          ? e.ref_last_name
                                          : "-"}
                                      </span>{" "}
                                      <br />
                                      <span>
                                        Email:{" "}
                                        {e.ref_email ? e.ref_email : "-"}
                                      </span>{" "}
                                      <br />
                                      <span>
                                        Ref.Address:{" "}
                                        {formatAddress(e.ref_address)}
                                      </span>
                                    </a>
                                    {e.ref_address ? (
                                      <Copy data={e.ref_address} />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <div className="">
                                      <div className="d-flex align-items-center">
                                        <h6>
                                          <span className="opacity-50">
                                            Days:
                                          </span>{" "}
                                          <span>
                                            {e?.days ? e?.days : "-"}
                                          </span>
                                        </h6>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <h6>
                                          <span className="opacity-50">
                                            Min:
                                          </span>{" "}
                                          <span>{e?.min ? e?.min : "-"}</span>
                                        </h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td>$ {e.amount ? e.amount : "0"}</td>
                                  <td>
                                    <span>
                                      {e?.token ? e?.token : "-"}{" "}
                                      {process.env.REACT_APP_TICKER}
                                    </span>
                                  </td>
                                  <td>{e.premium ? e.premium : "0"}</td>
                                  <td>{e.rate ? e.rate : "0"}</td>
                                  <td>
                                    {formatDateTime(e.time ? e.time : "-")}
                                  </td>
                                  <td>
                                    {formatDateTime(
                                      e.m_date ? e.m_date : "-"
                                    )}
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      {e.status === true ? (
                                        <>
                                          <div className="green-dot-active mx-2"></div>
                                          Active
                                        </>
                                      ) : (
                                        <>
                                          <div className="red-dot-active mx-2"></div>
                                          Deactive
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                            : ""}
                        </tbody>
                      </table>
                      {loading ? (
                        <div className="text-center">
                          <p>Loading...</p>
                        </div>
                      ) : !data || !data.length ? (
                        <div className="text-center">
                          <p>No data found.</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default RDList;
