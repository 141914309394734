import React, { useContext, useState, useEffect } from "react";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Footer from "../Comman/Footer";
import axios from "axios";
import { GMCContext } from "../../GMCContext";


function HashStake() {
  const { rdpkg, fdpkg, tokenrate, formatDateTime, isToggled, toggleMenu  } = useContext(GMCContext);
  const [address, setaddress] = useState(null);
  const [referral_address, setreferral_address] = useState(null);
  const [amount, setamount] = useState(0);
  const [receipt, setReceipt] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [msg, setMsg] = useState("");
  const [showError, setShowError] = useState(false);
  const [showMsg, setShowMsg] = useState(false);

  const insertData = async () => {
    setErrorMessage("");
    setShowError(false);
    setMsg("");
    setShowMsg(false);

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
        method: "transaction",
        submethod: "insertadmin",
        key: process.env.REACT_APP_KEY,
        address: address,
        ref_address: referral_address,
        amount: amount
      });

      const { resCode, error } = response.data;
      if (resCode === 200) {
        setMsg("Data inserted successfully");
        setShowMsg(true);

        setReceipt(null);
      } else {
        setErrorMessage(error || "An unexpected error occurred");
        setShowError(true);
      }
    } catch (err) {
      console.error("Error inserting data:", err);
      setErrorMessage("Network error. Please try again later.");
      setShowError(true);
    }
  };

  useEffect(() => {
    let timer;
    if (showError) {
      timer = setTimeout(() => setShowError(false), 2000);
    }
    if (showMsg) {
      timer = setTimeout(() => setShowMsg(false), 2000);
    }
    return () => clearTimeout(timer);
  }, [showError, showMsg]);

  return (
    <div id="main-wrapper" className={isToggled ? "show menu-toggle" : "show"}>
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container-fluid">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6">
              <div className="authincation-content rounded-5">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="tab-content">
                        <form>
                          <div className="form-group">
                            <label className="form-label text-white">
                              Address
                            </label>
                            <input
                              type="text"
                              className="form-control input-rounded bg-black"
                              value={address}
                              onChange={(e) =>
                                setaddress(e.target.value)
                              }
                              placeholder="Enter transaction Address"
                            />
                             <label className="form-label text-white">
                              RefAddress
                            </label>
                            <input
                              type="text"
                              className="form-control input-rounded bg-black"
                              value={referral_address}
                              onChange={(e) =>
                                setreferral_address(e.target.value)
                              }
                              placeholder="Enter transaction RefAddress"
                            />
                             <label className="form-label text-white">
                              Amount
                            </label>
                            <input
                              type="text"
                              className="form-control input-rounded bg-black"
                              value={amount}
                              onChange={(e) =>
                                setamount(e.target.value)
                              }
                              placeholder="Enter transaction Amount"
                            />
                            
                          </div>
                          
                          {showError && errorMessage && (
                            <div className="alert alert-danger" role="alert">
                              {errorMessage}
                            </div>
                          )}
                          {showMsg && msg && (
                            <div className="alert alert-success" role="alert">
                              {msg}
                            </div>
                          )}
                          <div className="d-flex justify-content-center mt-4">
                            <button
                              type="button"
                              className="d-flex justify-content-center align-items-center btn btn-primary w-50"
                              onClick={insertData}
                            >
                              Insert Data
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HashStake;
