import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Index from "./Components/Home/Index";
import Members from "./Components/Members/Members";
import Withdrawal from "./Components/Report/Withdrawal";
import MemberReport from "./Components/Members/MemberReport";
import Login from "./Components/Login";
import LevelIncome from "./Components/Report/LevelIncome";
import CommunityIncome from "./Components/Report/CommunityIncome";
import RoiOfRoi from "./Components/Report/RoiOfRoi";
import Stakings from "./Components/Report/Stakings";
import Unstake from "./Components/Report/Unstake";
import PendingWithdrawal from "./Components/Report/PendingWithdrawal";
import BlockA from "./Components/Block/BlockA";
import BlockB from "./Components/Block/BlockB";
import Cron from "./Components/Cron";
import RDList from "./Components/Report/RDList";
import RetirementReward from "./Components/Report/RetirementReward";
import Agency from "./Components/Report/Agency";
import Stake from "./Components/Stake/Stake";
import DailyRewardSidbar from "./Components/Report/DailyRewardSidbar";
import LevelRewardSidebar from "./Components/Report/LevelRewardSidebar";
import InvestmentSidbar from "./Components/Report/InvestmentSidbar";
import DirectRewardSidbar from "./Components/Report/DirectRewardSidbar";
import SalaryRewardSidbar from "./Components/Report/SalaryRewardSidbar";
import Rewardsidbar from "./Components/Report/Rewardsidbar";
import BonanzaSidbar from "./Components/Report/BonanzaSidbar";
import ThousandSalary from "./Components/SalaryManu/ThousandSalary";
import ThreeThousandFivehundred from "./Components/SalaryManu/ThreeThousandFivehundred";
import EightThousandFiveHundred from "./Components/SalaryManu/EightThousandFiveHundred";
import EighteenThousandFiveHundred from "./Components/SalaryManu/EighteenThousandFiveHundred";
import ThirtyThreeThousandFivehundred from "./Components/SalaryManu/ThirtyThreeThousandFivehundred";
import FiftyThreeThousandFivehundred from "./Components/SalaryManu/FiftyThreeThousandFivehundred";
import NinetyThreeThousandFivehundred from "./Components/SalaryManu/NinetyThreeThousandFivehundred";
import OneHundredFortyThreeThousandFivehundred from "./Components/SalaryManu/OneHundredFortyThreeThousandFivehundred";
import QrCodeAuth from "./Components/QrCodeAuth";
import ProtecteRoutes from "./Components/ProtecteRoutes";
import HashStake from "./Components/Stake/HashStake";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route path="/" element={<Login />} />
          {/* <Route path="/authentication" element={<QrCodeAuth />} /> */}

          {/* <Route element={<ProtecteRoutes />}> */}
            <Route path="/dashboard" element={<Index />} />
            <Route path="/users" element={<Members />} />
            <Route path="/userreport/:address" element={<MemberReport />} />
            <Route path="/aggregator-reward" element={<LevelIncome />} />
            <Route path="/mining-reward" element={<CommunityIncome />} />
            <Route path="/cold-reward" element={<RoiOfRoi />} />
            <Route path="/stakings" element={<Stakings />} />
            <Route path="/unstake" element={<Unstake />} />
            <Route path="/withdrawal" element={<Withdrawal />} />
            <Route path="/pending-withdrawal" element={<PendingWithdrawal />} />
            <Route path="/stake" element={<Stake />} />
            <Route path="/rd-list" element={<RDList />} />
            <Route path="/agency" element={<Agency />} />
            <Route path="/retirement-reward" element={<RetirementReward />} />
            <Route path="/block-a" element={<BlockA />} />
            <Route path="/block-b" element={<BlockB />} />
            <Route path="/crons" element={<Cron />} />
            <Route path="/dailyreward" element={<DailyRewardSidbar />} />
            <Route path="/levelreward" element={<LevelRewardSidebar />} />
            <Route path="/investment" element={<InvestmentSidbar />} />
            <Route path="/directreward" element={<DirectRewardSidbar />} />
            <Route path="/salaryreward" element={<SalaryRewardSidbar />} />
            <Route path="/reward" element={<Rewardsidbar />} />
            <Route path="/bonanzareward" element={<BonanzaSidbar />} />
            <Route path="/1000salary" element={<ThousandSalary />} />
            <Route path="/3500salary" element={<ThreeThousandFivehundred />} />
            <Route path="/8500salary" element={<EightThousandFiveHundred />} />
            <Route path="/hash" element={<HashStake />} />
            <Route
              path="/18500salary"
              element={<EighteenThousandFiveHundred />}
            />
            <Route
              path="/33500salary"
              element={<ThirtyThreeThousandFivehundred />}
            />
            <Route
              path="/53500salary"
              element={<FiftyThreeThousandFivehundred />}
            />
            <Route
              path="/93500salary"
              element={<NinetyThreeThousandFivehundred />}
            />
            <Route
              path="/143500salary"
              element={<OneHundredFortyThreeThousandFivehundred />}
            />
          </Route>
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
