import React, { useContext, useEffect, useState } from "react";
import { GMCContext } from "../../GMCContext";
import axios from "axios";
import Pagination from "../Comman/Pagination";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Copy from "../Comman/Copy";
import Footer from "../Comman/Footer";

export default function RoiOfRoi({ address }) {
  const { formatAddress, web3, contract, formatDateTime, isToggled, toggleMenu } =
    useContext(GMCContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [data, setdata] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const getData = async () => {
    try {
      setLoading(true);
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "withdrawal",
          submethod: "getpw",
          key: process.env.REACT_APP_KEY,
          search: search,
          pageSize: pageSize,
          page: currentPage,
        })
        .then((res) => {
          setdata(res.data.data);
          setPages(res.data.pages ? res.data.pages : 1);
          setLoading(false);
          if (res.data.error) {
            return "";
          }
        });
    } catch (err) {
      console.log("error : ", err);
      setLoading(false);
      setPages(1);
    }
  };
  console.log('data', data)
  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
    getData();
  }, [pages, pageSize, currentPage, search]);

  return (
    <>
      <div id="main-wrapper" className={isToggled ? "show menu-toggle" : "show"}>
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active text-primary">
                  Pending Withdrawal
                </li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="form-head d-flex align-items-center">
                      <div className="input-group search-area d-inline-flex m-3">
                        <input
                          type="text"
                          className="form-control form-control-rev pe-0"
                          placeholder="Search here"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="input-group-text input-group-text-rev "
                            onClick={() => setSearch("")}
                          >
                            {search ? (
                              <img
                                src="./assets/images/close.png"
                                alt=""
                                style={{ height: "30px" }}
                              />
                            ) : (
                              <i className="flaticon-381-search-2" />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <h3 class="py-3 px-5">Pending Withdrawal</h3>
                    <hr />
                    <div className="table-responsive">
                      <table
                        id="example5"
                        className="table table-striped patient-list mb-4 dataTablesCard fs-14"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Address</th>
                            <th>Amount</th>
                            <th>Token</th>
                            <th>Fee (10%)</th>
                            <th>Total receive</th>
                            <th>Rate</th>
                            <th>Datetime</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data && data?.length
                            ? data.map((e, i) => {
                              let ind =
                                currentPage * pageSize - pageSize + (i + 1);
                              return (
                                <tr>
                                  <td>{ind}</td>
                                  <td>
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={`${process.env.REACT_APP_EXPLORER}/address/${e.address}`}
                                      rel="noreferrer"
                                    >


                                      {formatAddress(e.address)}
                                    </a>
                                    {e.address ? (
                                      <Copy data={e.address} />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>$ {e.amount ? e.amount : "0"}</td>
                                  <td>
                                    {e.token ? e.token : "0"}{"  "}
                                    {e.token_ticker}
                                  </td>
                                  <td>
                                    {e.fee ? e.fee : "0"}{" "}
                                    {e.token_ticker}
                                  </td>
                                  <td>
                                    {e.t_rcv ? e.t_rcv : "0"}{" "}
                                    {e.token_ticker}
                                  </td>
                                  <td>{e.rate ? e.rate : "0"}</td>
                                  <td>
                                    {e.datetime ? e.datetime : "-"}
                                  </td>
                                </tr>
                              );
                            })
                            : ""}
                        </tbody>
                      </table>
                      {loading ? (
                        <div className="text-center">
                          <p>Loading...</p>
                        </div>
                      ) : !data || !data.length ? (
                        <div className="text-center">
                          <p>No data found.</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
