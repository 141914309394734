import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { GMCContext } from "../../GMCContext";
import { FaUsers } from "react-icons/fa";
import { FaRankingStar } from "react-icons/fa6";
import { PiCoinsLight } from "react-icons/pi";
import { GiRank3 } from "react-icons/gi";
import { MdOutlinePendingActions } from "react-icons/md";

export default function Sidebar() {
  const { setToggleAchieveRank, toggleAchieveRank } = useContext(GMCContext);
  const epoint = useLocation().pathname;

  const clearToggle = () => {
    setToggleAchieveRank("");
  };

  return (
    <>
      <div className="deznav">
        <div className="deznav-scroll">
          <ul className="metismenu" id="menu">
            <li
              className={`${
                epoint === "/dashboard" ? "mm-active active-no-child" : ""
              }`}
              onClick={clearToggle}
            >
              <Link
                className={`ai-icon `}
                to="/dashboard"
                aria-expanded="false"
              >
                <RxDashboard color="white" size="1.2rem" />
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            <li
              className={`${
                epoint === "/users" || epoint.includes("/userreport")
                  ? "mm-active active-no-child"
                  : ""
              }`}
              onClick={clearToggle}
            >
              <Link to="/users" className="ai-icon" aria-expanded="false">
                <FaUsers color="white" size="1.2rem" />
                <span className="nav-text">Users</span>
              </Link>
            </li>
            {/* <li className={toggleAchieveRank === "block" ? "mm-active" : ""}>
              <Link
                className="has-arrow ai-icon"
                onClick={() =>
                  setToggleAchieveRank(
                    toggleAchieveRank === "block" ? "" : "block"
                  )
                }
                >
                <FaCubes color="white" size="1.2rem" />
                <span className="nav-text">Block</span>
              </Link>
              <ul
                style={
                  toggleAchieveRank === "block"
                    ? {}
                    : { height: "16px", display: "none" }
                }
              >
                <li className={`${epoint === "/block-a" ? "mm-active" : ""}`}>
                  <Link to="/block-a" className="ai-icon">
                    <span className="nav-text">Block A</span>
                  </Link>
                </li>
                <li className={`${epoint === "/block-b" ? "mm-active" : ""}`}>
                <Link to="/block-b" className="ai-icon">
                <span className="nav-text">Block B</span>
                </Link>
                </li>
              </ul>
              </li> */}
            <li
              className={`${
                epoint === "/investment" ? "mm-active active-no-child" : ""
              }`}
              onClick={clearToggle}
            >
              <Link
                to="/investment"
                className="ai-icon"
                aria-expanded="false"
              >
                <PiCoinsLight color="white" size="1.2rem" />
                <span className="nav-text">Investments</span>
              </Link>
            </li>
           
            <li
              className={`${
                epoint === "/dailyreward" ? "mm-active active-no-child" : ""
              }`}
              onClick={clearToggle}
            >
              <Link
                to="/dailyreward"
                className="ai-icon"
                aria-expanded="false"
              >
                <PiCoinsLight color="white" size="1.2rem" />
                <span className="nav-text">Daily Reward</span>
              </Link>
            </li>
            <li
              className={`${
                epoint === "/directreward" ? "mm-active active-no-child" : ""
              }`}
              onClick={clearToggle}
            >
              <Link
                to="/directreward"
                className="ai-icon"
                aria-expanded="false"
              >
                <PiCoinsLight color="white" size="1.2rem" />
                <span className="nav-text">Direct Reward</span>
              </Link>
            </li>

            <li
              className={`${
                epoint === "/levelreward" ? "mm-active active-no-child" : ""
              }`}
              onClick={clearToggle}
            >
              <Link
                to="/levelreward"
                className="ai-icon"
                aria-expanded="false"
              >
                <PiCoinsLight color="white" size="1.2rem" />
                <span className="nav-text">Level Reward</span>
              </Link>
            </li>
            <li
              className={`${
                epoint === "/salaryreward" ? "mm-active active-no-child" : ""
              }`}
              onClick={clearToggle}
            >
              <Link
                to="/salaryreward"
                className="ai-icon"
                aria-expanded="false"
              >
                <GiRank3 color="white" size="1.2rem" />
                <span className="nav-text">Salary Reward</span>
              </Link>
            </li>
            <li
              className={`${
                epoint === "/reward" ? "mm-active active-no-child" : ""
              }`}
              onClick={clearToggle}
            >
              <Link to="/reward" className="ai-icon" aria-expanded="false">
                <GiRank3 color="white" size="1.2rem" />
                <span className="nav-text"> Reward</span>
              </Link>
            </li>
            <li
              className={`${
                epoint === "/bonanzareward" ? "mm-active active-no-child" : ""
              }`}
              onClick={clearToggle}
            >
              <Link to="/bonanzareward" className="ai-icon" aria-expanded="false">
                <GiRank3 color="white" size="1.2rem" />
                <span className="nav-text">Bonanza Reward</span>
              </Link>
            </li>
            <li
              className={`${
                epoint === "/withdrawal" ? "mm-active active-no-child" : ""
              }`}
              onClick={clearToggle}
            >
              <Link to="/withdrawal" className="ai-icon" aria-expanded="false">
                <GiRank3 color="white" size="1.2rem" />
                <span className="nav-text">Withdrawal</span>
              </Link>
            </li>
            <li
              className={`${
                epoint === "/dashboard" ? "mm-active active-no-child" : ""
              }`}
              onClick={clearToggle}
            >
              <Link
                className={`ai-icon `}
                to="/hash"
                aria-expanded="false"
              >
                <RxDashboard color="white" size="1.2rem" />
                <span className="nav-text">Add User</span>
              </Link>
            </li>
            {/* <li
              className={`${
                epoint === "/agency" ? "mm-active active-no-child" : ""
              }`}
              onClick={clearToggle}
            >
              <Link to="/agency" className="ai-icon" aria-expanded="false">
                <GiRank3 color="white" size="1.2rem" />
                <span className="nav-text">Pending Withdrawal</span>
              </Link>
            </li> */}
            <li
                className={
                  toggleAchieveRank === "achieve-rank" ? "mm-active" : ""
                }
              >
                <Link
                  className="has-arrow ai-icon"
                  onClick={() =>
                    setToggleAchieveRank(
                      toggleAchieveRank === "achieve-rank" ? "" : "achieve-rank"
                    )
                  }
                >
                  <FaRankingStar color="white" size="1.2rem" />
                  <span className="nav-text"> Salary Menu</span>
                </Link>
                <ul
                  style={
                    toggleAchieveRank === "achieve-rank"
                      ? {}
                      : { height: "16px", display: "none" }
                  }
                >
                  <li
                    className={`${
                      epoint === "/1000salary" ? "mm-active" : ""
                    }`}
                  >
                    <Link to="/1000salary" className="ai-icon">
                      <span className="nav-text">1000</span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      epoint === "/3500salary" ? "mm-active" : ""
                    }`}
                  >
                    <Link to="/3500salary" className="ai-icon">
                      <span className="nav-text">3500</span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      epoint === "/8500salary" ? "mm-active" : ""
                    }`}
                  >
                    <Link to="/8500salary" className="ai-icon">
                      <span className="nav-text">8500</span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      epoint === "/18500salary" ? "mm-active" : ""
                    }`}
                  >
                    <Link to="/18500salary" className="ai-icon">
                      <span className="nav-text">18500</span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      epoint === "33500salary" ? "mm-active" : ""
                    }`}
                  >
                    <Link to="/33500salary" className="ai-icon">
                      <span className="nav-text">33500</span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      epoint === "/53500salary" ? "mm-active" : ""
                    }`}
                  >
                    <Link to="/53500salary" className="ai-icon">
                      <span className="nav-text">53500</span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      epoint === "/93500salary" ? "mm-active" : ""
                    }`}
                  >
                    <Link to="/93500salary" className="ai-icon">
                      <span className="nav-text">93500</span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      epoint === "/143500salary" ? "mm-active" : ""
                    }`}
                  >
                    <Link to="/143500salary" className="ai-icon">
                      <span className="nav-text">143500</span>
                    </Link>
                  </li>
                  
                </ul>
              </li>
            {/* <li
              className={`${
                epoint === "/retirement-reward"
                  ? "mm-active active-no-child"
                  : ""
              }`}
              onClick={clearToggle}
            >
            <Link
            to="/retirement-reward"
            className="ai-icon"
            aria-expanded="false"
              >
                <GiRank3 color="white" size="1.2rem" />
                <span className="nav-text">Retirement Reward</span>
              </Link>
            </li> */}
            {/* <li
              className={`${
                epoint === "/aggregator-reward"
                ? "mm-active active-no-child"
                  : ""
              }`}
              onClick={clearToggle}
              >
              <Link
                to="/aggregator-reward"
                className="ai-icon"
                aria-expanded="false"
                >
                <FaMoneyBillTrendUp color="white" size="1.2rem" />
                <span className="nav-text">Aggregator Reward</span>
                </Link>
                </li> */}
            {/* <li
              className={`${
                epoint === "/mining-reward" ? "mm-active active-no-child" : ""
                }`}
                onClick={clearToggle}
                >
                <Link
                to="/mining-reward"
                className="ai-icon"
                aria-expanded="false"
                >
                <GiMining color="white" size="1.2rem" />
                <span className="nav-text">Mining Reward</span>
                </Link>
            </li> */}
            {/* <li
              className={`${
                epoint === "/cold-reward" ? "mm-active active-no-child" : ""
              }`}
              onClick={clearToggle}
            >
            <Link to="/cold-reward" className="ai-icon" aria-expanded="false">
            <WiSnowflakeCold color="white" size="1.2rem" />
                <span className="nav-text">Cold Reward</span>
              </Link>
            </li> */}
            {/* <li
              className={`${
                epoint === "/stakings" ? "mm-active active-no-child" : ""
              }`}
              onClick={clearToggle}
              >
              <Link to="/stakings" className="ai-icon" aria-expanded="false">
              <GrStakeholder color="white" size="1.2rem" />
              <span className="nav-text">Stakings</span>
              </Link>
              </li> */}
             
            {/* <li
              className={`${
                epoint === "/unstake" ? "mm-active active-no-child" : ""
              }`}
              onClick={clearToggle}
            >
              <Link to="/unstake" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-id-card-4" />
                <span className="nav-text">Unstake</span>
              </Link>
            </li> */}
            {/* <li
              className={`${
                epoint === "/withdrawal" ? "mm-active active-no-child" : ""
              }`}
              onClick={clearToggle}
            >
              <Link to="/withdrawal" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-gift" />
                <span className="nav-text">Withdrawal</span>
              </Link>
            </li> */}
            <li
              className={`${
                epoint === "/pending-withdrawal"
                  ? "mm-active active-no-child"
                  : ""
              }`}
              onClick={clearToggle}
            >
              <Link
                to="/pending-withdrawal"
                className="ai-icon"
                aria-expanded="false"
              >
                <MdOutlinePendingActions color="white" size="1.2rem" />
                <span className="nav-text">Pending-Withdrawal</span>
              </Link>
            </li>
            <li
              className={`${
                epoint === "/crons" ? "mm-active active-no-child" : ""
              }`}
              onClick={clearToggle}
            >
              <Link to="/crons" className="ai-icon" aria-expanded="false">
                <MdOutlinePendingActions color="white" size="1.2rem" />
                <span className="nav-text">Crons</span>
              </Link>
            </li>
            {/* <li className={`${epoint === "/statements" ? "mm-active active-no-child" : ""}`}>
              <Link to="/statements" className="ai-icon" aria-expanded="false">
                <IoIosList color="white" size="1.2rem" />
                <span className="nav-text">Statements</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}
