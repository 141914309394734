import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { GMCContext } from "../../GMCContext";

export default function Copy({ data }) {
  const { copyText } = useContext(GMCContext);
  const [isCopied, setIsCopied] = useState(false);

  const copydataa = () => {
    copyText(data);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  return (
    <>
      <span className="me-3 text-primary" onClick={() => copydataa(data)}>
        &nbsp;
        {isCopied ? (
          <i className="fa fa-check fs-14  " aria-hidden="true" />
        ) : (
          <i className="fa fa-copy fs-14 " aria-hidden="true" />
        )}
      </span>
    </>
  );
}
