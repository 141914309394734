import React, { useContext, useEffect, useState } from "react";
import Footer from "./Comman/Footer";
import Header from "./Comman/Header";
import Sidebar from "./Comman/Sidebar";
import axios from "axios";
import Web3 from "web3";
import { GMCContext } from "../GMCContext";
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function Cron() {
  const { isToggled, toggleMenu } = useContext(GMCContext);
  const [countDown1, setCountDown1] = useState("00:00:00");
  const date1 = new Date(); // Current date
  date1.setDate(date1.getDate() + 1); // Add 1 day
  const deadline1 = date1.setHours(0, 1, 0, 0); // Set hours to 10 and minutes to 25;
  const getTime1 = () => {
    const time = new Date(deadline1) - Date.now();
    setCountDown1(
      `${Math.floor(time / (1000 * 60 * 60))}:${Math.floor((time / 1000 / 60) % 60)}:${Math.floor((time / 1000) % 60)}`
    );
  };
  useEffect(() => {
    const interval = setInterval(() => getTime1(deadline1), 1000);
    return () => clearInterval(interval);
  }, []);

  const [countDown2, setCountDown2] = useState("00:00:00");
  const date2 = new Date(); // Current date
  date2.setDate(date2.getDate() + 1); // Add 1 day
  const deadline2 = date2.setHours(0, 10, 0, 0); // Set hours to 10 and minutes to 25;
  const getTime2 = () => {
    const time = new Date(deadline2) - Date.now();
    setCountDown2(
      `${Math.floor(time / (1000 * 60 * 60))}:${Math.floor((time / 1000 / 60) % 60)}:${Math.floor((time / 1000) % 60)}`
    );
  };
  useEffect(() => {
    const interval = setInterval(() => getTime2(deadline2), 1000);
    return () => clearInterval(interval);
  }, []);

  const [countDown3, setCountDown3] = useState("00:00:00");
  const date3 = new Date(); // Current date
  date3.setDate(date3.getDate() + 1); // Add 1 day
  const deadline3 = date3.setHours(0, 20, 0, 0); // Set hours to 10 and minutes to 25;
  const getTime3 = () => {
    const time = new Date(deadline3) - Date.now();
    setCountDown3(
      `${Math.floor(time / (1000 * 60 * 60))}:${Math.floor((time / 1000 / 60) % 60)}:${Math.floor((time / 1000) % 60)}`
    );
  };
  useEffect(() => {
    const interval = setInterval(() => getTime3(deadline3), 1000);
    return () => clearInterval(interval);
  }, []);

  const [countDown4, setCountDown4] = useState("00:00:00");
  const date4 = new Date(); // Current date
  date4.setDate(date4.getDate() + 1); // Add 1 day
  const deadline4 = date4.setHours(0, 25, 0, 0); // Set hours to 10 and minutes to 25;
  const getTime4 = () => {
    const time = new Date(deadline4) - Date.now();
    setCountDown4(
      `${Math.floor(time / (1000 * 60 * 60))}:${Math.floor((time / 1000 / 60) % 60)}:${Math.floor((time / 1000) % 60)}`
    );
  };
  useEffect(() => {
    const interval = setInterval(() => getTime4(deadline4), 1000);
    return () => clearInterval(interval);
  }, []);

  const [countDown5, setCountDown5] = useState("00:00:00");
  const date5 = new Date(); // Current date
  date5.setDate(date5.getDate() + 1); // Add 1 day
  const min = date5.getMinutes();
  const rem = min % 5;
  const plus = rem !== 0 ? 5 - rem : 5;
  const hr = date5.getHours();
  const cmin = date5.getMinutes();
  date5.setDate(new Date().getDate());
  const [deadline5, setDeadline5] = useState(date5.setHours(hr, cmin + plus, 0, 0));
  const getTime5 = () => {
    const time = new Date(deadline5) - Date.now();
    if (time > 0) {
      setCountDown5(
        `${Math.floor(time / (1000 * 60 * 60))}:${Math.floor((time / 1000 / 60) % 60)}:${Math.floor(
          (time / 1000) % 60
        )}`
      );
    } else {
      let newd = new Date();
      newd.setDate(newd.getDate() + 1); // Add 1 day
      const min = newd.getMinutes();
      const rem = min % 5;
      const plus = rem !== 0 ? 5 - rem : 5;
      const hr = newd.getHours();
      const cmin = newd.getMinutes();
      newd.setDate(new Date().getDate());
      setDeadline5(newd.setHours(hr, cmin + plus, 0, 0));
    }
  };
  useEffect(() => {
    const interval = setInterval(() => getTime5(deadline5), 1000);
    return () => clearInterval(interval);
  }, [deadline5]);

  return (
    <>
      <div id="main-wrapper" className={isToggled ? "show menu-toggle" : "show"}>
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
              <div className="me-auto d-none d-lg-block">
                <h3 className="text-black font-w600">Scheduled Crons</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Mining Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">{countDown1}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Staking Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">{countDown2}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Get Unstake Data</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">{countDown3}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Unstake Call</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">{countDown4}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Next Withdrawal Start In</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">{countDown5}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">User Smart Contract</p>
                        <div className="d-flex flex-wrap">
                          {!loading ? (
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#confirmUpd"
                            >
                              <i className="fa fa-plus " aria-hidden="true" />
                            </button>
                          ) : (
                            ""
                          )}
                          <div>{loading ? "Data updating....." : ""}</div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <GoldRequest /> */}
          </div>
        </div>
        {/* <div className="modal fade" id="confirmUpd">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Are you sure??</h5>
                <div className="modal-footer">
                  <button type="button" className="btn btn-danger light" data-bs-dismiss="modal">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary" onClick={() => getCheck()} data-bs-dismiss="modal">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <Footer />
      </div>
    </>
  );
}
