import React, { useContext, useEffect, useState } from "react";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Footer from "../Comman/Footer";
import { useParams } from "react-router";
import { GMCContext } from "../../GMCContext";
import axios from "axios";
import Copy from "../Comman/Copy";
import Withdrawal from "./Withdrawal";
import Investments from "./Investments";
import Team from "./Team";
import LevelReward from "./LevelReward";
import SalaryReward from "./SalaryReward";
import Reward from "./Reward";
import DailyRewardMember from "./DailyRewardMember";
import DirectReward from "./DirectReward";

export default function MemberReport() {
  const { address } = useParams();
  const { formatAddress, formatDateTime, isToggled, toggleMenu } = useContext(GMCContext);
  const [data, setdata] = useState([]);
  const [totReward, setTotReward] = useState(0);

  const getData = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "getbyid",
        key: process.env.REACT_APP_KEY,
        address,
      })
      .then((res) => {
        if (res.data.data) {
          var fbln =
            res.data.data.level_rew +
            res.data.data.staking_rew +
            res.data.data.heritage_rew +
            res.data.data.community_rew +
            res.data.data.community_royalty -
            res.data.data.t_cold_withdrawal -
            res.data.data.t_other_withdrawal;
          setTotReward(fbln);
          // console.log("res.data:",res.data)
          setdata(res.data.data);
        }
        if (res.data.error) {
          return "";
        }
      });
  };
  // console.log("data:",data)
  useEffect(() => {
    if (address) {
      getData();
    }
  }, [address]);
  return (
    <>
      <div id="main-wrapper" className={isToggled ? "show menu-toggle" : "show"}>
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">
                  <a href="#">Member Report</a>
                </li>
              </ol>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="profile-statistics">
                  <div className="row">
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">Address</p>
                      <h5 className="m-b-0 text-center">
                        <a
                          target="_blank"
                          className="text-primary"
                          href={`${process.env.REACT_APP_EXPLORER}/address/${address}`}
                          rel="noreferrer"
                        >
                          {formatAddress(address)}
                        </a>
                        {address ? <Copy data={address} /> : ""}
                      </h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center">Ref. Address</p>
                      <h5 className="m-b-0 text-center">
                        {data?.ref_address ? (
                          <>
                            <a
                              target="_blank"
                              className="text-primary"
                              href={`${process.env.REACT_APP_EXPLORER}/address/${data?.ref_address}`}
                              rel="noreferrer"
                            >
                              {formatAddress(data?.ref_address)}
                            </a>
                            <Copy data={data?.ref_address} />
                          </>
                        ) : (
                          "-"
                        )}
                      </h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">Bonanza</p>
                      <h5 className="text-center">
                        <div className="d-flex justify-content-center align-items-center">
                          {data?.bonanza === true ? (
                            <>
                              <div className="green-dot-active mx-2"></div>
                              Active
                            </>
                          ) : (
                            <>
                              <div className="red-dot-active mx-2"></div>
                              Deactive
                            </>
                          )}
                        </div>
                      </h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">Team</p>
                      <h5 className="text-center">{data?.team ? data?.team : "0"}</h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">Direct Business</p>
                      <h5 className="text-center">${data?.direct_team_business ? data?.direct_team_business : "0"}</h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">Stake</p>
                      <h5 className="text-center">${data?.t_staking ? data?.t_staking : "0"}</h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">Capability</p>
                      <h5 className="text-center">{data?.income_capability ? data?.income_capability : "0"} X</h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">Daily %</p>
                      <h5 className="text-center">{data?.daily_per ? data?.daily_per : "0"}%</h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">Directs</p>
                      <h5 className="text-center">{data?.d_team ? data?.d_tea : "0"}</h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">Daily Reward</p>
                      <h5 className="text-center">${data?.staking_inc ? data?.staking_inc : "0"}</h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">Direct Reward</p>
                      <h5 className="text-center">${data?.level_inc ? data?.level_inc : "0"}</h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">Salary Reward</p>
                      <h5 className="text-center">${data?.salary_inc ? data?.salary_inc : "0"}</h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">Reward</p>
                      <h5 className="text-center">${data?.reward_inc ? data?.reward_inc : "0"}</h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">Level Reward</p>
                      <h5 className="text-center">${data?.level_inc ? data?.level_inc : "0"}</h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">Strong Leg</p>
                      <h5 className="text-center">$ {data?.strong_leg ? data?.strong_leg : "0"}</h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">Other Leg</p>
                      <h5 className="text-center">$ {data?.oleg ? data?.oleg : "0"}</h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">R.Strong Leg</p>
                      <h5 className="text-center">$ {data?.r_aleg ? data?.r_aleg : "0"}</h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">R. Other Leg</p>
                      <h5 className="text-center">$ {data?.r_oleg ? data?.r_oleg : "0"}</h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">Salary</p>
                      <h5 className="text-center">${data?.salary_inc ? data?.salary_inc : "0"}</h5>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                      <p className="text-center w-100">Week</p>
                      <h5 className="text-center">{data?.week ? data?.week : "0"}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Investments address={address} />
            <Team address={address} />
            <DailyRewardMember address={address} />
            <DirectReward address={address} />
            <LevelReward address={address} />
            <SalaryReward address={address} />
            <Reward address={address} />
            <Withdrawal address={address} />
            {/* <Withdrawal address={address} />
            <StakingIncome address={address} />
            <Unstake address={address} />
            <PendingWithdrawal address={address} /> */}
            {/* <ClaimMember address={address} /> */}
            {/* <LevelIncome address={address} /> */}
            {/* <RankRewardMember address={address} /> */}
            {/* <CommunityIncome address={address} /> */}
            {/* <RoiOfRoi address={address} /> */}
            {/* <Stakings address={address} /> */}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
